import { IAddress } from '../../models/IAddress';

export enum IndustryType {
    Barber = 'barber',
    HairSalon = 'hair_salon',
    MedSpa = 'med_spa',
    Tattoo = 'tattoo',
    Fitness = 'fitness',
    Other = 'other'
}

export const IndustryOptions: Record<IndustryType, string> = {
    [IndustryType.Barber]: 'Barber',
    [IndustryType.HairSalon]: 'Hair Salon',
    [IndustryType.MedSpa]: 'Med Spa',
    [IndustryType.Tattoo]: 'Tattoo',
    [IndustryType.Fitness]: 'Fitness',
    [IndustryType.Other]: 'Other'
};

// https://developers.google.com/maps/documentation/places/web-service/place-types#business
export const IndustriesMapping: Partial<Record<IndustryType, string[]>> = {
    [IndustryType.Barber]: ['barber_shop'],
    [IndustryType.HairSalon]: ['hair_care', 'hair_salon'],
    [IndustryType.MedSpa]: ['spa'],
    [IndustryType.Tattoo]: ['body_art_service'],
    [IndustryType.Fitness]: ['fitness_center']
};

export interface Suggestion {
    placePrediction: {
        placeId: string;
        structuredFormat: {
            mainText: {
                text: string;
            };
            secondaryText: {
                text: string;
            };
        };
    };
}

export interface AutocompleteResponse {
    suggestions?: Suggestion[];
    error?: string;
}

export interface AutocompleteOption {
    mainText: string;
    secondaryText: string;
    placeId: string;
    sessionToken: string;
}

export interface PlaceAddressComponent {
    longText: string;
    shortText: string;
    types: string[];
    languageCode: string;
}

export interface PlaceResponse {
    types: string[];
    internationalPhoneNumber?: string;
    formattedAddress: string;
    addressComponents: PlaceAddressComponent[];
    websiteUri?: string;
    regularOpeningHours?: object;
    error?: string;
}

export interface Place {
    address: IAddress;
    industry?: IndustryType | '';
    site?: string;
    phone?: string;
    opening_hours?: Pick<PlaceResponse, 'regularOpeningHours'>;
    google_place_id: string;
}
