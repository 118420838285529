import { PaymentGateways } from '../../../../../../../../types';

export const methodOptions = [
    { value: PaymentGateways.Paypal, label: 'Paypal' },
    { value: PaymentGateways.Stripe, label: 'Stripe' },
    { value: PaymentGateways.AuthorizeNet, label: 'Authorize.net' },
    { value: PaymentGateways.ATHM, label: 'ATH Móvil' },
    { value: 'cash', label: 'Cash' },
    { value: 'credit_card', label: 'Credit Card' },
    { value: 'venmo', label: 'Venmo' },
    { value: 'zelle', label: 'Zelle' },
    { value: 'gift_card', label: 'Gift Card' },
    { value: 'other', label: 'Other' }
];

export const paymentReasons = {
    gratuity: 'gratuity',
    deposit: 'deposit',
    service: 'service',
    other: 'other',
    coupon: 'coupon'
};

export const reasonOptions = [
    { value: paymentReasons.deposit, label: 'Deposit' },
    { value: paymentReasons.service, label: 'Service' },
    { value: paymentReasons.gratuity, label: 'Gratuity' },
    { value: paymentReasons.coupon, label: 'Coupon' },
    { value: paymentReasons.other, label: 'Other' }
];
