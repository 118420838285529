import { useCallback, useEffect, useMemo } from 'react';

import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import moment from 'moment/moment';

import config, { Envs } from 'config';

import ATHMLogo from 'assets/images/icons/athm-circle-logo.svg';

import { IService } from 'models/IService';
import { PaymentGateways } from 'types/index';

import { SnackBarTypes } from 'store/snackbarReducer';
import useShowSnackbar from 'hooks/useShowSnackbar';

import { TimeSlot } from 'views/scheduling-widget/widget-wizard/types';
import useDepositFunctions from 'views/scheduling-widget/hooks/useDepositFunctions';

// ATH Movil necessary methods
declare global {
    interface Window {
        ATHM_Checkout: any; // ATH Movil configuration object
        authorizationATHM: () => Promise<void>;
        cancelATHM: () => Promise<void>;
        expiredATHM: () => Promise<void>;
        authorization: () => Promise<any>;
        findPaymentATHM: () => Promise<any>;
        sendATHMPayment: () => void;
    }
}

const StyledATHMButton = styled(Button)(() => ({
    backgroundColor: '#f57c00',
    '&:hover': {
        backgroundColor: '#d66a00'
    }
}));

interface ATHMPayButtonProps {
    publicToken: string;
    services: IService[];
    date: Pick<TimeSlot, 'start_at'> | null;
    submitBooking: (details?: object) => void;
    amount?: number;
    disabled?: boolean;
}

const ATHMPayButton = ({ publicToken, services, date, submitBooking, amount, disabled }: ATHMPayButtonProps) => {
    const { showSnackbar } = useShowSnackbar();

    const { servicePrice } = useDepositFunctions();

    const price = useMemo(() => {
        const val = amount || servicePrice;
        return val.toFixed(2);
    }, [amount, servicePrice]);

    useEffect(() => {
        // Define ATHM_Checkout object
        window.ATHM_Checkout = {
            env: 'production', // Yep, ATM only production env is supported
            publicToken: publicToken,
            theme: 'btn',
            lang: 'en',
            total: price,
            metadata1: `serviceIds: ${services.map(({ id }) => id).join(', ')}`, // req but ???
            metadata2: '', // req but ???
            items: [
                {
                    name: `Deposit Payment ${date ? moment(date.start_at).format('MMMM Do YYYY, hh:mm A') : ''}`,
                    quantity: 1,
                    price: price
                }
            ],
            phoneNumber: ''
        };

        // Callback functions for ATH Movil
        window.authorizationATHM = async () => {
            const authorizationResponse = await window.authorization();
            submitBooking({ ...authorizationResponse, gateway: PaymentGateways.ATHM });
        };

        window.cancelATHM = async () => {
            // const responseCancel = await window.findPaymentATHM();
            showSnackbar({
                message: 'Error occurred. Payment has been canceled. Please try again or select another payment option.',
                alertSeverity: SnackBarTypes.Error
            });
        };

        window.expiredATHM = async () => {
            // const responseExpired = await window.findPaymentATHM();
            showSnackbar({
                message: 'Error occurred. Payment has been expired. Please try again or select another payment option.',
                alertSeverity: SnackBarTypes.Error
            });
        };

        // Load external scripts
        const loadScript = (src: string): Promise<void> =>
            new Promise((resolve, reject) =>
                document.body.appendChild(
                    Object.assign(document.createElement('script'), {
                        src,
                        async: true,
                        onload: resolve,
                        onerror: () => reject(new Error(`Failed to load script: ${src}`))
                    })
                )
            );

        // Initialize ATH Movil
        const initializeATHMovil = async () => {
            try {
                await loadScript('https://payments.athmovil.com/api/js/athmovil_base.js');

                // Simulate DOMContentLoaded event to initialize ATH Movil
                document.dispatchEvent(new Event('DOMContentLoaded', { bubbles: true, cancelable: true }));
            } catch (error) {
                console.error('Error initializing ATH Movil:', error);
            }
        };

        initializeATHMovil();
    }, [date, price, publicToken, services, showSnackbar, submitBooking]);

    const fakeAuthorizationResponse = useCallback(() => {
        return {
            status: 'success',
            data: {
                ecommerceStatus: 'COMPLETED',
                ecommerceId: '870633c9-f994-11ed-8935-c155d7fc6afe',
                referenceNumber: '215070440-8a36d420882a293a018849cae9f500a8',
                businessCustomerId: '402894d56e713892016e7f2963de0010',
                transactionDate: moment().utc().format(),
                dailyTransactionId: '0001',
                businessName: 'CB ATHM Integration',
                businessPath: 'cbathm',
                industry: 'WebDevelopment',
                subTotal: price,
                tax: 0,
                total: window.ATHM_Checkout.total,
                fee: 0,
                netAmount: 0,
                totalRefundedAmount: 0,
                metadata1: window.ATHM_Checkout.metadata1,
                metadata2: window.ATHM_Checkout.metadata2,
                items: window.ATHM_Checkout.items,
                isNonProfit: false
            }
        };
    }, [price]);

    const athmButtonClickHandler = useCallback(() => {
        if (config.env === Envs.Production) {
            window.sendATHMPayment();
            return;
        } else {
            submitBooking({ ...fakeAuthorizationResponse(), gateway: PaymentGateways.ATHM });
        }
    }, [fakeAuthorizationResponse, submitBooking]);

    return (
        <>
            <Box sx={{ display: 'none' }} id="ATHMovil_Checkout_Button_payment"></Box>

            <StyledATHMButton
                variant="contained"
                color="primary"
                onClick={athmButtonClickHandler}
                startIcon={<Box component="img" src={ATHMLogo} alt="ATH Móvil Logo" sx={{ width: '1.5em', height: 'auto' }} />}
                disabled={disabled}
                fullWidth
            >
                ATH Móvil
            </StyledATHMButton>
        </>
    );
};

export default ATHMPayButton;
